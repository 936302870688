import React, { useState, useEffect, useRef, useCallback } from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import MainMenu from '../../pages/main/MainMenu'
import Eventing from '../../pages/eventing/Eventing'
import Projects from '../../pages/projects/Projects'
import News from '../../pages/news/News'
import Network from '../../pages/network/Network'
import Blog from '../../pages/blog/Blog'
import Team from '../../pages/team/Team'
import EventDetail from '../../pages/eventing/EventDetail'
import NewsDetail from '../../pages/news/NewsDetail'
import './Header.scss'

function Header({ activeLanguage, toggleLanguage }) {
  const [isLanguagePopupOpen, setLanguagePopupOpen] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isHeaderVisible, setHeaderVisible] = useState(true)
  const sortRef = useRef()
  const [activePage, setActivePage] = useState('/')

  const toggleLanguagePopup = () => {
    setLanguagePopupOpen(!isLanguagePopupOpen)
  }

  const handleLanguageChange = (language) => {
    toggleLanguage()
    setLanguagePopupOpen(false)
  }

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen)
  }

  const handleOutsideClick = useCallback((e) => {
    if (!sortRef.current.contains(e.target)) {
      setLanguagePopupOpen(false)
    }
  }, [])

  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick)

    return () => {
      document.body.removeEventListener('click', handleOutsideClick)
    }
  }, [handleOutsideClick])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 990) {
        setHeaderVisible(false)
      } else {
        setHeaderVisible(true)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const pages = [
    { id: 'homepage', path: '/' },
    { id: 'events', path: '/eventing' },
    { id: 'projects', path: '/projects' },
    { id: 'news', path: '/news' },
    { id: 'network', path: '/network' },
    { id: 'blog', path: '/blog' },
    { id: 'team', path: '/team' },
  ]

  return (
    <Router>
      <header className={isHeaderVisible ? '' : 'hidden'}>
        <div className="container">
          <div className="header">
            <div className="logo">
              <img
                width={93}
                height={94}
                src="./img/mini-logo.png"
                alt="logo"
              />
            </div>

            <nav className={`header__menu ${isMenuOpen ? 'open' : ''}`}>
              <ul>
                {pages.map((page, index) => (
                  <li key={index}>
                    <Link
                      to={page.path}
                      onClick={() => {
                        setMenuOpen(false)
                        setActivePage(page.path)
                      }}
                      className={activePage === page.path ? 'active' : ''}
                    >
                      <FormattedMessage id={page.id} />
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>

            <div ref={sortRef} className="language-switcher">
              <button onClick={toggleLanguagePopup}>
                <img
                  width={60}
                  height={40}
                  src={
                    activeLanguage === 'uk'
                      ? './img/ua-language1.png'
                      : './img/eng-language.png'
                  }
                  alt="language"
                />
                <img
                  width={40}
                  height={40}
                  src="./img/ua-arrow.png"
                  alt="language-arrow"
                />
              </button>
              {isLanguagePopupOpen && (
                <div className="language-popup">
                  <ul>
                    <li
                      onClick={() => handleLanguageChange('uk')}
                      className={activeLanguage === 'uk' ? 'active' : ''}
                    >
                      Українська
                    </li>
                    <li
                      onClick={() => handleLanguageChange('en')}
                      className={activeLanguage === 'en' ? 'active' : ''}
                    >
                      English
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className={`burger ${isMenuOpen ? 'open' : ''}`}
          onClick={toggleMenu}
        >
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>

        {isMenuOpen && (
          <div className="mobile-menu">
            <ul>
              {pages.map((page, index) => (
                <li key={index}>
                  <Link to={page.path} onClick={() => setMenuOpen(false)}>
                    <FormattedMessage id={page.id} />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        <Routes>
          <Route exact path="/" element={<MainMenu />} />
          <Route path="/eventing" element={<Eventing />} />
          <Route path="/event/:eventIndex" element={<EventDetail />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:newsIndex" element={<NewsDetail />} />
          <Route path="/network" element={<Network />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/team" element={<Team />} />
        </Routes>
      </header>
    </Router>
  )
}

export default Header
