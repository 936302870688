import React from 'react'
import { FormattedMessage } from 'react-intl'
import './Footer.scss'

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="container">
          <div className="footer__inner">
            <div className="footer__contacts">
              <h5 className="footer__title">
                <FormattedMessage id="contacts" />
              </h5>
              <ul className="footer__social">
                <li className="footer__social-item">
                  <a href="mailto:pnrm.ukraine@gmail.com">
                    <img
                      className="footer__icon"
                      src="img/footer/e-mail_icon.png"
                      alt="email"
                    />
                    pnrm.ukraine@gmail.com
                  </a>
                </li>
                <li className="footer__social-item">
                  <a href="tel:+380632194913">
                    <img
                      className="footer__icon"
                      src="img/footer/phone_icon.png"
                      alt="phone"
                    />
                    +3 8063 219 49 13
                  </a>
                </li>
                <li className="footer__social-item">
                  <a
                    href="https://t.me/+YxvybUYgwGFhNzky"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="footer__icon"
                      src="img/footer/telegram_icon.png"
                      alt="telegram"
                    />
                    <FormattedMessage id="channel" />
                  </a>
                </li>
                <li className="footer__social-item">
                  <a
                    href="https://t.me/+J1cr33Ae8pA3ZGMy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="footer__icon"
                      src="img/footer/telegram_icon.png"
                      alt="telegram"
                    />
                    <FormattedMessage id="chat" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer__partners">
              <h5 className="footer__title">
                <FormattedMessage id="partners" />
              </h5>
              <a
                href="https://ugf.academy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footer__partner-logo"
                  src="img/footer/UGF_logo.png"
                  alt="partners"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
