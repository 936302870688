import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import SliderGallery from '../../components/slider/SliderGallery'
import './MainMenu.scss'

const OurDirection = ({ photo, description }) => (
  <div className="direction__item">
    <div className="direction__icon">
      <img src={photo} alt="direction icon" />
      <span>
        <FormattedMessage id={description} />
      </span>
    </div>
  </div>
)

const ourDirections = [
  {
    id: 1,
    photo: './img/categories_mini/Development of Manager.png',
    description: 'direction.description1',
  },
  {
    id: 2,
    photo: './img/categories_mini/Grant Activities.png',
    description: 'direction.description2',
  },
  {
    id: 3,
    photo: './img/categories_mini/Higher Education.png',
    description: 'direction.description3',
  },
  {
    id: 4,
    photo: './img/categories_mini/Innovation and Transfer.png',
    description: 'direction.description4',
  },
  {
    id: 5,
    photo: './img/categories_mini/Science Communication.png',
    description: 'direction.description5',
  },
  {
    id: 6,
    photo: './img/categories_mini/Management of displacement.png',
    description: 'direction.description6',
  },
]

function MainMenu() {
  const intl = useIntl()
  const bannerImagePath = intl.formatMessage({ id: 'banner.main' })
  return (
    <div className="container">
      <div className="banner-main">
        <img src={bannerImagePath} alt="banner_main" />
      </div>
      <div className="mission">
        <h2>
          <FormattedMessage id="mission.title" />
        </h2>
        <p>
          <FormattedMessage id="mission.subtitle" />
        </p>
      </div>
      <div className="direction">
        <h2>
          <FormattedMessage id="direction.title" />
        </h2>
        <div className="direction__items">
          {ourDirections.map((directions, index) => (
            <OurDirection
              key={index}
              photo={directions.photo}
              description={directions.description}
            />
          ))}
        </div>
        <div className="direction__subtitle">
          <p>
            <FormattedMessage id="direction.subtitle" />
          </p>
        </div>
      </div>
      <div className="social-media">
        <h2>
          <FormattedMessage id="social.media" />
        </h2>
        <div className="social-media__icon">
          <a
            href="https://twitter.com/pnrm_ukraine"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="./img/social media/twitter.png" alt="twitter" />
          </a>
          <a
            href="https://www.linkedin.com/company/pnrm-ukraine/about/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="./img/social media/linkedin.png" alt="in" />
          </a>
          <a href="mailto:pnrm.ukraine@gmail.com">
            <img src="./img/social media/e-mail.png" alt="mail" />
          </a>
        </div>
      </div>
      <div className="photogallery">
        <h2 className="photogallery__title">
          <FormattedMessage id="photogallery" />
        </h2>
        <div className="photogallery__container">
          <SliderGallery />
        </div>
      </div>
      <div className="donate">
        <h2 className="donate__title">
          <FormattedMessage id="donate" />
        </h2>
        <a
          href="https://send.monobank.ua/jar/pJW2wViSQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="donate__image"
            src="./img/dollar-cat.png"
            alt="donate"
          />
        </a>
      </div>
    </div>
  )
}

export default MainMenu
